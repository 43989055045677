import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../components/Dashboard/Dashboard';
import Login from '../components/Login/Login';
import ProtectedRoutes from './ProtectedRoutes';
import Layout from '../components/Layout/Layout';
import ScheduleDashboard from '../components/ScheduleDashboard/ScheduleDashboard';
import DataTable from '../components/DataTable/DataTable';
import Roles from '../components/Roles';
import Summary from '../components/Summary';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {/* Rutas protegidas con auth */}
      <Route element={<ProtectedRoutes />}>
        <Route
          index
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
        <Route
          path="/schedule" // La ruta que quieres para tu componente Schedule
          element={
            <Layout>
              <ScheduleDashboard />
            </Layout>
          }
        />
        <Route
          path="/dashboard"
          element={
            <Layout>
              <DataTable />
            </Layout>
          }
        />
        <Route
          path="/roles"
          element={
            <Layout>
              <Roles />
            </Layout>
          }
        />
        <Route
          path="/summary"
          element={
            <Layout>
              <Summary />
            </Layout>
          }
        />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
