
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserNotifications, fetchAdminNotifications } from '../../api/api';

export interface NotificationState {
  'Fuera de Plazo': number;
  'Validación Rechazada': number;
  'Revisión Rechazada': number;
  'Ejecución Atrasada': number;
  'Liberación': number;
}

export interface NotificationsState {
  notifications: NotificationState;
  loading: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: NotificationsState = {
  notifications: {
    'Fuera de Plazo': 0,
    'Validación Rechazada': 0,
    'Revisión Rechazada': 0,
    'Ejecución Atrasada': 0,
    'Liberación': 0,
  },
  loading: 'idle',
  error: null,
};


export const fetchUserNotificationsThunk = createAsyncThunk(
  'notifications/fetchUserNotifications',
  async (userId: number, { rejectWithValue }) => {
    try {
      const notifications = await fetchUserNotifications(userId);
      return notifications;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Error fetching user notifications');
    }
  }
);


export const fetchAdminNotificationsThunk = createAsyncThunk(
  'notifications/fetchAdminNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const notifications = await fetchAdminNotifications();
      return notifications;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Error fetching admin notifications');
    }
  }
);


const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserNotificationsThunk.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchUserNotificationsThunk.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(fetchUserNotificationsThunk.rejected, (state, action) => {
      state.notifications = initialState.notifications;
      state.loading = 'failed';
      state.error = action.payload as string;
    });
    builder.addCase(fetchAdminNotificationsThunk.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchAdminNotificationsThunk.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(fetchAdminNotificationsThunk.rejected, (state, action) => {
      state.notifications = initialState.notifications;
      state.loading = 'failed';
      state.error = action.payload as string;
    });
  },
});

export default notificationsSlice.reducer;
