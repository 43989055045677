import React, { useState } from 'react';
import {
  Control,
  Controller,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  RegulationFormValues,
  User,
  ResponsibilityType,
  Regulation,
} from '../../DataTable/dataTypes';
import ScheduleFields from './ScheduleFields';
import ResponsibilityFields from './ResponsibilityFields';
import TaskWorksheetsComponent from '../../TaskWorksheetsComponent/TaskWorksheetsComponent';
import {
  uploadTaskWorksheet,
  downloadTaskWorksheet,
  deleteTaskWorksheet,
} from '../../../api/tasksApi';
import LoadingModal from '../../modals/LoadingModal/LoadingModal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { updateRegulation } from '../../../store/slices/regulationSlice';

interface TaskFieldsProps {
  control: Control<RegulationFormValues>;
  users: User[];
  types: ResponsibilityType[];
  disabled?: boolean;
}

const TaskFields: React.FC<TaskFieldsProps> = ({
  control,
  users,
  types,
  disabled,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tasks',
  });

  const { getValues, setValue } = useFormContext();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const addTask = () => {
    const taskIndex = fields.length;
    append({
      description: '',
      preparationDays: 0,
      number: taskIndex + 1,
      regulationId: 0,
      periodicity: 'monthly',
      taskUserResponsibilities: [],
      worksheets: [],
    });
  };

  const handleUploadWorksheet = async (taskIndex: number) => {
    if (selectedFile) {
      setLoading(true);
      try {
        const taskId = getValues(`tasks.${taskIndex}.id`);
        const response = await uploadTaskWorksheet(selectedFile, taskId);
        const worksheets = getValues(`tasks.${taskIndex}.worksheets`) || [];
        if (response) {
          const updatedWorksheets = [...worksheets, response];
          setValue(`tasks.${taskIndex}.worksheets`, updatedWorksheets);
        }
        setSelectedFile(null);
        const regulation = getValues() as Regulation;
        dispatch(updateRegulation(regulation));
      } catch (error) {
        console.error('Error uploading worksheet:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDownloadWorksheet = async (worksheetId: number) => {
    try {
      const { blob, fileName } = await downloadTaskWorksheet(worksheetId);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error downloading worksheet:', error);
    }
  };

  const handleDeleteWorksheet = async (
    taskIndex: number,
    worksheetId: number
  ) => {
    setLoading(true);
    try {
      await deleteTaskWorksheet(worksheetId);
      const worksheets = getValues(`tasks.${taskIndex}.worksheets`) || [];
      const updatedWorksheets = worksheets.filter(
        (ws: any) => ws.id !== worksheetId
      );
      setValue(`tasks.${taskIndex}.worksheets`, updatedWorksheets);
      const regulation = getValues() as Regulation;
      dispatch(updateRegulation(regulation));
    } catch (error) {
      console.error('Error deleting worksheet:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingModal open={loading} />
      <h3>Tareas</h3>
      {fields.map((field, index) => (
        <Box
          key={field.id}
          sx={{ border: '1px solid lightgray', position: 'relative' }}
          mb={'20px'}
          p={3}
        >
          <Typography fontSize={'110%'} mb={2} fontWeight={'bold'}>
            Tarea {index + 1}
          </Typography>
          {!disabled && (
            <IconButton
              onClick={() => remove(index)}
              sx={{ position: 'absolute', top: '10px', right: '10px' }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
            <Controller
              name={`tasks.${index}.description`}
              control={control}
              rules={{ required: 'Descripción de la tarea es obligatorio' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Descripción de la Tarea"
                  error={!!error}
                  helperText={error ? error.message : null}
                  fullWidth
                  disabled={disabled} // Deshabilitar en modo solo lectura
                />
              )}
            />
            <Typography>Calendario</Typography>
            <ScheduleFields
              control={control}
              taskIndex={index}
              disabled={disabled}
              firstDueDate={field?.firstDueDate}
            />
            <Typography>Responsables de la Tarea</Typography>
            <Divider />
            <Box>
              <Box display={'flex'} flexWrap={'wrap'} gap={'10px'}>
                <Controller
                  name={`tasks.${index}.taskUserResponsibilities`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <ResponsibilityFields
                        control={control}
                        taskIndex={index}
                        users={users}
                        types={types}
                        disabled={disabled}
                      />
                    </>
                  )}
                />
              </Box>
            </Box>
            {field.id && !disabled && (
              <>
                <TaskWorksheetsComponent
                  worksheets={getValues(`tasks.${index}.worksheets`)}
                  onDownload={handleDownloadWorksheet}
                  onDelete={(worksheetId: number) =>
                    handleDeleteWorksheet(index, worksheetId)
                  }
                />
                <Box>
                  <input
                    type="file"
                    onChange={(e) =>
                      setSelectedFile(e.target.files?.[0] || null)
                    }
                    disabled={disabled} // Deshabilitar en modo solo lectura
                  />
                  <br />
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleUploadWorksheet(index)}
                    disabled={!selectedFile || disabled} // Deshabilitar si no hay archivo o si está en modo solo lectura
                    sx={{ padding: '5px', fontSize: '12px' }}
                  >
                    Guardar Template
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      ))}
      {!disabled && (
        <Button variant="contained" color="primary" onClick={addTask}>
          Añadir Tarea
        </Button>
      )}
    </>
  );
};

export default TaskFields;
