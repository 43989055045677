import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSummaryGraph, summarySelector } from '../../store/slices/summarySlice';
import { percentFormatOneDigit } from '../../helpers/formatter';


export default function Summary() {

	const dispatch = useDispatch();
	const { groupData, categoryData, loading } = useSelector(summarySelector);

	useEffect(() => {
		dispatch(getSummaryGraph({
			"start": "2024-01-01",
			"end": "2025-12-31"
		}))
	}, [dispatch])

	const label = (x) => {
		return <Fragment>
			<Typography fontWeight={'bold'}>
				{`${x.name} (${x.total})`}
			</Typography>
		</Fragment>
	};

	const infoBox = (x) => {
		const finalizado = x.aprobada + x.fueraPlazo;
		const cumplimiento = finalizado / x.total * 100;
		const plazo = x.aprobada / finalizado * 100;
		const fueraPlazo = x.fueraPlazo / finalizado * 100;

		return <Fragment>
			<Typography fontWeight={'bold'} textAlign={"center"}>
				Cumplimiento
			</Typography>
			<Typography textAlign={"center"}>
				{`${percentFormatOneDigit(cumplimiento)} (${finalizado}/${x.total})`}
			</Typography>

			<Grid container direction={"row"} justifyContent={"space-around"}>
				<Grid>
					<Typography fontSize={12} fontWeight={'bold'} textAlign={"center"}>En plazo {`(${x.aprobada})`}</Typography>
					<Typography fontSize={12} textAlign={"center"}>{`${percentFormatOneDigit(plazo)}`}</Typography>
				</Grid>
				<Grid>
					<Typography fontSize={12} fontWeight={'bold'} textAlign={"center"}>Fuera de plazo {`(${x.fueraPlazo})`}</Typography>
					<Typography fontSize={12} textAlign={"center"}>{`${percentFormatOneDigit(fueraPlazo)}`}</Typography>					
				</Grid>
			</Grid>
			{/* <Typography>{x.total}</Typography> */}
		</Fragment>
	}

	const summaryBox = (data) => data.map(x =>
		<Grid container key={x.name} border={1} marginBottom={1}>
			{/* Grupos */}
			<Grid container direction={"row"}>
				<Grid container width={'10%'} border={1} justifyContent={"center"} alignItems={"center"}>
					{label(x)}
				</Grid>

				<Grid container width={'20%'} border={1} justifyContent={"center"} alignItems={"center"}>
					<Grid width={'100%'}>
						{infoBox(x)}
					</Grid>
				</Grid>

				<Grid width={'70%'}>

					{x.list.map((x, i) =>
						<Grid key={i} container direction={"row"}>
							{/* Categorias */}
							<Grid container width={'25%'} border={1} justifyContent={"center"} alignItems={"center"}>
								{label(x)}
							</Grid>

							<Grid container width={'25%'} border={1} justifyContent={"center"} alignItems={"center"}>
								<Grid width={'100%'}>
									{infoBox(x)}
								</Grid>
							</Grid>

							<Grid width={'50%'}>

								{x.list.map((x, i) =>
									<Grid key={i} container direction={"row"}>
										{/* Alcance */}
										<Grid container width={'50%'} border={1} justifyContent={"center"} alignItems={"center"}>
											{label(x)}
										</Grid>

										<Grid container width={'50%'} border={1} justifyContent={"center"} alignItems={"center"}>
											<Grid width={'100%'}>
												{infoBox(x)}
											</Grid>
										</Grid>
									</Grid>
								)}

							</Grid>

						</Grid>

					)}

				</Grid>
			</Grid>

		</Grid>
	)

	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<h3>Tabla de resumen</h3>
			</Box>

			{loading ?
				<div>Cargando infomación , espere un momento!</div>
				:

				<Fragment>
					<h3>Por grupos</h3>
					{summaryBox(groupData)}
					<h3>Por categorias</h3>
					{summaryBox(categoryData)}
				</Fragment>

			}

		</>
	)
}