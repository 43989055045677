import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSummaryGraphs } from '../../api/summaryApi';


const initialState = {
	groupData: [],
	categoryData: [],
	loading: false,
	loadingSave: false
}

export const getSummaryGraph = createAsyncThunk(
	'summary/getGraph',
	async (period) => {
		console.log(period)
		const data = await fetchSummaryGraphs(period);		
		return data;
	}
)

const summarySlice = createSlice({
	name: "summarySlice",
	initialState,
	extraReducers: builder => {
		builder
			.addCase(getSummaryGraph.pending, (state) => {				
				state.groupData= []
				state.categoryData= []
				state.loading = true
			})
			.addCase(getSummaryGraph.fulfilled, (state, action) => {
				state.groupData= action.payload.groupData
				state.categoryData= action.payload.categoryData
				state.loading = false
			})
			.addCase(getSummaryGraph.rejected, (state, action) => {
				state.users = []
				state.loading = false
			})
	}
})

export const summarySelector = state => state.summary;
export default summarySlice.reducer;