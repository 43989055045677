import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import {
  fetchAdminNotificationsThunk,
  fetchUserNotificationsThunk,
} from '../../store/slices/notificationsSlice';


interface NotificationState {
  'Fuera de Plazo'?: number | null;
  'Validación Rechazada'?: number | null;
  'Revisión Rechazada'?: number | null;
  'Ejecución Atrasada'?: number | null;
  'Liberación'?: number | null;
}

export default function NotificationsMenu() {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.user);
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications as NotificationState
  );

  
  useEffect(() => {
    if (user) {
      if (user.roles && (user.roles as string[]).includes('admin')) {
        dispatch(fetchAdminNotificationsThunk());
        console.log('dispatch notificaciones admin')
      } else {
        dispatch(fetchUserNotificationsThunk(user.id));
        console.log('dispatch notificaciones usuario')
      }
    }
  }, [dispatch, user]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const totalNotifications = Object.values(notifications).filter(
    (value) => value && value > 0
  ).length;

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Box
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Badge badgeContent={totalNotifications} color="error">
            <NotificationsIcon />
          </Badge>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <List>
          {Object.entries(notifications).map(([key, value]) =>
            value && value > 0 ? (
              <MenuItem key={key}>
                <ListItem>
                  <ListItemText primary={`${key}: ${value}`} />
                </ListItem>
              </MenuItem>
            ) : null
          )}
        </List>
      </Menu>
    </React.Fragment>
  );
}
