import React from 'react';
import { Box, Typography, Divider, Grid } from '@mui/material';
import DetailItem from '../DetailItem/DetailItem';
import ArchivosComponent from './ArchivosComponent';
import { Task, TaskInstance } from '../../types/interfaces';
import { handleDownloadDocument, handleDeleteDocument } from './TaskHandlers';
import { Dispatch, SetStateAction } from 'react';
import { Regulation } from '../DataTable/dataTypes';
import TaskWorksheetsComponent from '../TaskWorksheetsComponent/TaskWorksheetsComponent';
import { downloadTaskWorksheet } from '../../api/tasksApi';

interface TaskDetailsProps {
  task: Task;
  regulation: Regulation;
  editedTaskInstance: TaskInstance | null;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setEditedTaskInstance: Dispatch<SetStateAction<TaskInstance | null>>;
  isDisabled?: boolean;
}

const TaskDetails: React.FC<TaskDetailsProps> = ({
  task,
  regulation,
  editedTaskInstance,
  setLoading,
  setEditedTaskInstance,
  isDisabled,
}) => {
  const getTaskTitle = () => {
    const periodicityHash = {
      specifies: 'Especifica',
      daily: 'Diaria',
      weekly: 'Semanal',
      monthly: 'Mensual',
      quarterly: 'Trimestral',
      semiannually: 'Semestral',
      annually: 'Anual',
    };
    const periodicity = task?.periodicity
      ? periodicityHash[task.periodicity]
      : '';
    return `Tarea ${task?.number} (${periodicity} - ${task?.preparationDays} días de preparación)`;
  };

  const handleDelete = (documentId: number) => {
    handleDeleteDocument(documentId, setLoading, setEditedTaskInstance);
  };

  const handleDownloadWorksheet = async (worksheetId: number) => {
    try {
      const { blob, fileName } = await downloadTaskWorksheet(worksheetId);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error downloading worksheet:', error);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <DetailItem title="Tipo" content={regulation?.type?.type} />
        <DetailItem title="Alcance" content={regulation?.scope?.name} />
        <DetailItem
          title="Categoría"
          content={regulation?.category?.category}
        />
      </Box>
      <DetailItem
        title="Título de Referencia"
        content={regulation?.document?.referenceTitle}
      />
      <DetailItem
        title="Descripción del Documento"
        content={regulation?.document?.description}
      />
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle2" color={'secondary'}>
          {getTaskTitle()}
        </Typography>
        <Divider />
        <Typography variant="body2">{task?.description}</Typography>
      </Box>
      <Typography variant="subtitle2" color={'secondary'}>
        Responsables
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {task?.taskUserResponsibilities?.map((responsibility, index) => (
            <Typography key={index} fontSize={'14px'}>
              {responsibility.user?.username}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={6}>
          {task?.taskUserResponsibilities?.map((responsibility, index) => (
            <Typography key={index} fontSize={'14px'}>
              {responsibility.responsibility?.type}
            </Typography>
          ))}
        </Grid>
      </Grid>
      <br />
      {editedTaskInstance?.task?.worksheets &&
        editedTaskInstance.task.worksheets.length > 0 && (
          <>
            <TaskWorksheetsComponent
              worksheets={editedTaskInstance.task.worksheets}
              onDownload={handleDownloadWorksheet}
              isDisabled={isDisabled}
            />
            <br />
          </>
        )}
      {editedTaskInstance?.documents &&
        editedTaskInstance.documents.length > 0 && (
          <>
            <ArchivosComponent
              taskInstance={editedTaskInstance}
              onDownload={handleDownloadDocument}
              onDelete={handleDelete}
              isDisabled={isDisabled}
            />
          </>
        )}
    </>
  );
};

export default TaskDetails;
