import axios from './axiosConfig';
import { API_BASE_URL } from './axiosConfig';

export const fetchSummaryGraphs = async (period) => {
	try {
		const response = await axios.post(
			`${API_BASE_URL}/regulations/summary`, 
			period,
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error('Error fetching summary graphs:', error);
		throw error;
	}
}